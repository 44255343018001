/* ======================================================================== */
/* 32. headerSticky */
/* ======================================================================== */
.js-sticky-header
	transition: all 0.6s ease
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0)
	will-change: padding, box-shadow
.header_sticky
	position: fixed
	top: 0
	left: 0
	width: 100%
	padding: 15px 0
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1)

/* ======================================================================== */
/* 82. sidebar */
/* ======================================================================== */
.sidebar_no-margin-last-widget
	.widget
		&:last-child
			margin-bottom: 0
.sidebar
	.widget
		margin-bottom: 50px
	.search-form
		margin-top: 0
		
@media only screen and (max-width: $md)
	.sidebar
		margin-top: 80px

@media only screen and (max-width: $sm)
	.sidebar
		margin-top: 60px

/* ======================================================================== */
/* 58. sectionBlog */
/* ======================================================================== */
.section-blog
	.search-form
		margin-top: 2em
.section-blog__wrapper-post
	padding-bottom: 50px
	margin-bottom: 50px
	border-bottom: 1px solid $borders-dark
	&:last-child
		padding-bottom: 0
		margin-bottom: 0
		border-bottom: none

@media only screen and (max-width: $sm)
	.section-blog__wrapper-post
		padding-bottom: 30px
		margin-bottom: 30px

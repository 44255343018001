/* ======================================================================== */
/* 5. buttonBordered */
/* ======================================================================== */
.button_bordered
	&.button_accent-primary-1
		border-color: var(--color-accent-primary-1)
		color: var(--color-accent-primary-1)
		&:focus
			color: var(--color-accent-primary-1)
		&:hover
			background-color: var(--color-accent-primary-1)
			color: $white
	&.button_accent-primary-2
		border-color: var(--color-accent-primary-2)
		color: var(--color-accent-primary-2)
		&:focus
			color: var(--color-accent-primary-2)
		&:hover
			background-color: var(--color-accent-primary-2)
			color: $white
	&.button_accent-primary-3
		border-color: var(--color-accent-primary-3)
		color: var(--color-accent-primary-3)
		&:focus
			color: var(--color-accent-primary-3)
		&:hover
			background-color: var(--color-accent-primary-3)
			color: $white
	&.button_accent-primary-4
		border-color: var(--color-accent-primary-4)
		color: var(--color-accent-primary-4)
		&:focus
			color: var(--color-accent-primary-4)
		&:hover
			background-color: var(--color-accent-primary-4)
			color: $white
	&.button_accent-secondary-1
		border-color: var(--color-accent-secondary-1)
		color: var(--color-accent-secondary-1)
		&:focus
			color: var(--color-accent-secondary-1)
		&:hover
			background-color: var(--color-accent-secondary-1)
			color: $white
	&.button_accent-secondary-2
		border-color: var(--color-accent-secondary-2)
		color: var(--color-accent-secondary-2)
		&:focus
			color: var(--color-accent-secondary-2)
		&:hover
			background-color: var(--color-accent-secondary-2)
			color: $white
	&.button_accent-secondary-3
		border-color: var(--color-accent-secondary-3)
		color: var(--color-accent-secondary-3)
		&:focus
			color: var(--color-accent-secondary-3)
		&:hover
			background-color: var(--color-accent-secondary-3)
			color: $white
	&.button_accent-secondary-4
		border-color: var(--color-accent-secondary-4)
		color: var(--color-accent-secondary-4)
		&:focus
			color: var(--color-accent-secondary-4)
		&:hover
			background-color: var(--color-accent-secondary-4)
			color: $white
	&.button_dark
		border-color: $greyish-brown
		color: $greyish-brown
		&:focus
			color: $greyish-brown
		&:hover
			background-color: $greyish-brown
			color: $white
	&.button_light
		border-color: $light
		color: $light
		&:focus
			color: $light
		&:hover
			background-color: $light
			color: $dark
	&.button_white
		border-color: $white
		color: $white
		&:focus
			color: $white
		&:hover
			background-color: $white
			color: $dark

/* ======================================================================== */
/* 27. grid */
/* ======================================================================== */
.grid
	overflow: hidden
.grid__sizer
	padding: 0 !important
	margin: 0 !important
	height: 0 !important
.grid__item
	display: block
	width: 100%
.grid__item-link
	display: block
	position: relative
	img
		+trans1
		transform-origin: center center
	&:hover, &:focus
		opacity: 1
	&:hover
		img
			transform: scale(1.1)
		.grid__item-overlay
			transform: scaleY(1)
		.grid__item-icon
			transform: translate(-50%, -50%)
			opacity: 1
			visibility: visible
			transition-delay: 50ms
			&:after
				transform: scale(1)
				transition-delay: 100ms
.grid__item-icon
	display: flex
	align-items: center
	justify-content: center
	position: absolute
	width: 80px
	height: 80px
	top: 50%
	left: 50%
	z-index: 50
	transform: translate(-50%, 10px)
	transition: all 0.6s ease
	opacity: 0
	visibility: hidden
	color: $white
	&:after
		content: ''
		display: block
		border-radius: 100%
		border: 1px solid $brown-grey
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		transform: scale(0)
		+trans1
.grid__item-icon-symbol
	font-size: 28px !important
.grid__item-overlay
	opacity: 1
	background-color: $dark
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 1
	+trans1
	transform: scaleY(0)
	transform-origin: bottom center

.grid__item-icon
	width: 60px
	height: 60px
.grid__item-icon-symbol
	font-size: 24px !important

@media only screen and (min-width: $md + 1px)
	.grid__item_desktop-12
		flex: 0 0 100%
		max-width: 100%
	.grid__item_desktop-6
		flex: 0 0 50%
		max-width: 50%
	.grid__item_desktop-4
		flex: 0 0 33.333333%
		max-width: 33.333333%
	.grid__item_desktop-3
		flex: 0 0 25%
		max-width: 25%

@media only screen and (min-width: $sm + 1px) and (max-width: $md)
	.grid__item_tablet-12
		flex: 0 0 100%
		max-width: 100%
	.grid__item_tablet-6
		flex: 0 0 50%
		max-width: 50%
	.grid__item_tablet-4
		flex: 0 0 33.333333%
		max-width: 33.333333%
	.grid__item_tablet-3
		flex: 0 0 25%
		max-width: 25%

@media only screen and (max-width: $sm)
	.grid__item_mobile-12
		flex: 0 0 100%
		max-width: 100%
	.grid__item_mobile-6
		flex: 0 0 50%
		max-width: 50%
	.grid__item_mobile-4
		flex: 0 0 33.333333%
		max-width: 33.333333%
	.grid__item_mobile-3
		flex: 0 0 25%
		max-width: 25%

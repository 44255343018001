/* ======================================================================== */
/* 94. transitionCurtain */
/* ======================================================================== */
.transition-curtain
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 400
	display: none
	transition: background-color 0.6s ease-in-out
	will-change: background-color

/* ======================================================================== */
/* 9. colorsAccentPrimary */
/* ======================================================================== */
.color-accent-primary-1
	color: var(--color-accent-primary-1)
.color-accent-primary-2
	color: var(--color-accent-primary-2)
.color-accent-primary-3
	color: var(--color-accent-primary-3)
.color-accent-primary-4
	color: var(--color-accent-primary-4)

/* ======================================================================== */
/* 80. sectionTeamThemes */
/* ======================================================================== */
.section-team
	&.bg-dark, &.bg-dark-2, &.bg-dark-3
		.figure-member__heading
			color: $white
		.social__item a
			color: $white
			&:hover
				color: $sandstone

/* ======================================================================== */
/* 87. sliderLetters */
/* ======================================================================== */
.slider-letters
	width: 100%
	height: 100%
.vector-letter:not(:first-child)
	visibility: hidden
svg.vector-letters
	// width: 160px
	height: 130px
	
// .slider-letters__letter
// 	font-family: $font-secondary
// 	font-size: 130px
// 	line-height: 1
// 	font-weight: bold
// 	padding-bottom: 25px
// .slider-letters__wrapper-letters
// 	position: relative
// 	width: 100%
// 	height: 100%
// .slider-letters__slide
// 	display: flex
// 	align-items: center
// 	justify-content: center

/* ======================================================================== */
/* 31. headerOverlayThemes */
/* ======================================================================== */
.header__wrapper-overlay-menu
	&.bg-white
		.header__curtain
			background-color: $white
		.menu-overlay li a
			color: $dusk
			&:hover
				color: $dark-blue-2
		.vector-letter
			fill: $dusk
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-off-white
		.header__curtain
			background-color: $off-white
		.menu-overlay li a
			color: $dusk
			&:hover
				color: $dark-blue-2
		.vector-letter
			fill: $dusk
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-light
		.header__curtain
			background-color: $light
		.menu-overlay li a
			color: $dusk
			&:hover
				color: $dark-blue-2
		.vector-letter
			fill: $dusk
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-black
		.header__curtain
			background-color: $black
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-dark
		.header__curtain
			background-color: $dark
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-dark-2
		.header__curtain
			background-color: $dark2
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-dark-3
		.header__curtain
			background-color: $dark3
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-accent-primary-1
		.header__curtain
			background-color: $sandstone
		.menu-overlay li a
			color: $light
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
		.header__overlay-menu-back
			color: $dusk
	/////////////////////////////////////////////
	&.bg-accent-primary-2
		.header__curtain
			background-color: $pale
	/////////////////////////////////////////////
	&.bg-accent-primary-3
		.header__curtain
			background-color: $pinkish-grey
	/////////////////////////////////////////////
	&.bg-accent-primary-4
		.header__curtain
			background-color: $brownish-grey
	/////////////////////////////////////////////
	&.bg-accent-secondary-1
		.header__curtain
			background-color: $grey-blue
		.menu-overlay li a
			color: $white
			&:hover
				color: $pale
		.vector-letter
			fill: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-2
		.header__curtain
			background-color: $dusk
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white

	/////////////////////////////////////////////
	&.bg-accent-secondary-3
		.header__curtain
			background-color: $dark-blue
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white
	/////////////////////////////////////////////
	&.bg-accent-secondary-4
		.header__curtain
			background-color: $dark-blue-2
		.menu-overlay li a
			color: $pinkish-grey
			&:hover
				color: $sandstone
		.vector-letter
			fill: $white

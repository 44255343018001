/* ======================================================================== */
/* 16. figureImage */
/* ======================================================================== */
.figure-image
	position: relative
	text-align: center
	img
		width: 100%
.figure-image__wrapper-img
	position: relative
	overflow: hidden

/* ======================================================================== */
/* 90. social */
/* ======================================================================== */
.social
	+reset-ul
	margin-left: -16px
	margin-right: -16px
.social__item
	display: inline-block
	margin: 0 8px
	a
		color: $brown-grey
		font-size: 13px
		padding: 8px
		&:hover
			color: $sandstone

/* ======================================================================== */
/* 110. widgetRecentComments */
/* ======================================================================== */
.widget_recent_comments
	ul
		li
			padding: 20px 0
			margin-bottom: 0 !important
			border-bottom: 1px solid $borders-dark
			&:first-child
				padding-top: 0
			&:last-child
				padding-bottom: 0
				border-bottom: none
			a
				color: $dusk
				font-family: $font-secondary
				font-weight: bold
				&:hover
					color: $sandstone 
	.comment-author-link
		a
			color: $brown-grey
			font-family: $font-primary
			font-weight: normal

/* ======================================================================== */
/* 47. scroll */
/* ======================================================================== */
.smooth-scroll
	overflow: hidden
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	// transform: scaleY(0)
	// background-color: $light-blue-grey-2
.scroll-content
	// position: fixed
	// top: 0
	// left: 0
	// width: 100vw
	// height: 100vh
	// will-change: transform
[data-os-animation]
	opacity: 0
	visibility: hidden
[data-os-animation="animated"]
	opacity: 1
	visibility: visible

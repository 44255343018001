/* ======================================================================== */
/* 6. buttonSolid */
/* ======================================================================== */
.button_solid
	&.button_accent-primary-1
		background-color: var(--color-accent-primary-1)
		border-color: var(--color-accent-primary-1)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-primary-4)
			background-color: var(--color-accent-primary-4)
	&.button_accent-primary-2
		background-color: var(--color-accent-primary-2)
		border-color: var(--color-accent-primary-2)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-primary-4)
			background-color: var(--color-accent-primary-4)
	&.button_accent-primary-3
		background-color: var(--color-accent-primary-3)
		border-color: var(--color-accent-primary-3)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-primary-4)
			background-color: var(--color-accent-primary-4)
	&.button_accent-primary-4
		background-color: var(--color-accent-primary-4)
		border-color: var(--color-accent-primary-4)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-primary-3)
			background-color: var(--color-accent-primary-3)
	&.button_accent-secondary-1
		background-color: var(--color-accent-secondary-1)
		border-color: var(--color-accent-secondary-1)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-secondary-4)
			background-color: var(--color-accent-secondary-4)
	&.button_accent-secondary-2
		background-color: var(--color-accent-secondary-2)
		border-color: var(--color-accent-secondary-2)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-secondary-4)
			background-color: var(--color-accent-secondary-4)
	&.button_accent-secondary-3
		background-color: var(--color-accent-secondary-3)
		border-color: var(--color-accent-secondary-3)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-secondary-4)
			background-color: var(--color-accent-secondary-4)
	&.button_accent-secondary-4
		background-color: var(--color-accent-secondary-4)
		border-color: var(--color-accent-secondary-4)
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: var(--color-accent-secondary-3)
			background-color: var(--color-accent-secondary-3)
	&.button_dark
		background-color: $greyish-brown
		border-color: $greyish-brown
		color: $white
		&:focus
			color: $greyish-brown
		&:hover
			background-color: $dark
			color: $white
	&.button_light
		background-color: $light
		border-color: $light
		color: $dusk
		&:focus
			color: $light
		&:hover
			background-color: $very-light-pink
			border-color: $very-light-pink
			color: $dark
	&.button_white
		background-color: $white
		border-color: $white
		color: $dusk
		&:focus
			color: $white
		&:hover
			background-color: $very-light-pink
			border-color: $very-light-pink
			color: $dark

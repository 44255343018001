/* ======================================================================== */
/* 93. splitText */
/* ======================================================================== */
.elementor-editor-active
	[data-os-animation]
		.split-text
			opacity: 1
			visibility: visible
.clone
	.split-text__line
		position: static !important
	.split-text__word
		position: static !important

/* ======================================================================== */
/* 38. logo */
/* ======================================================================== */
.logo
	position: relative
	color: $sandstone
	font-weight: bold
	display: inline-flex
.logo__wrapper-img
	align-items: center
	flex: 1 0 auto
	height: 100%
	img
		height: 50px

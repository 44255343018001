/* ======================================================================== */
/* 56. sectionAboutThemes */
/* ======================================================================== */
.section-about
	&.bg-dark, &.bg-dark-2, &.bg-dark-3
		.section-about__heading
			color: $white
		.counter__number
			color: $sandstone
		.counter__label
			color: $white

/* ======================================================================== */
/* 13. counter */
/* ======================================================================== */
.counter
	text-align: center
	color: $brown-grey
.counter__number
	font-family: $font-primary
	font-weight: 100
.counter__label
	margin-top: 5px
	font-weight: 400

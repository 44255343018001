/* ======================================================================== */
/* 1. asideLogos */
/* ======================================================================== */
.aside-logos
.aside-logos__wrapper-logo
	display: flex
	align-items: center
	justify-content: center
	text-align: center
	padding-top: 25px
	padding-bottom: 25px
	// @include fluid-padding-top($min_width, $max_width, 10, 25)
	// @include fluid-padding-bottom($min_width, $max_width, 10, 25)
	img
		max-height: 100px
		width: auto !important
		// display: inline-block
		top: 50% !important
		left: 50% !important
		transform: translate(-50%, -50%)
		
@media only screen and (max-width: $sm)
	.aside-logos__wrapper-logo
		.lazy
			padding: 0 !important
		img
			top: 0 !important
			left: 0 !important
			transform: none
			position: relative !important
